import { Button, Typography } from 'glints-aries';
import styled from 'styled-components';

import GlintsContainer from 'src/components/GlintsContainer';

export const HeroGraphicContainer = styled.div`
  height: auto;
  object-fit: contain;
  width: 100%;
  justify-self: end;
  grid-area: img;

  @media (min-width: 640px) {
    width: calc(50vw - 19px);
    height: auto;
  }

  @media (min-width: 992px) {
    width: 40vw;
  }
`;

export const Photo = styled.img`
  display: block;
  width: inherit;
  height: inherit;
`;

export const Container = styled(GlintsContainer)`
  display: grid;
  gap: 16px;
  padding: 16px 0 16px 16px;
  align-items: center;
  width: 100vw;
  background: radial-gradient(
    265.25% 111.61% at 6.84% 7.25%,
    rgba(207, 235, 248, 0.95) 1.83%,
    rgba(255, 255, 255, 0.95) 100%
  );
  grid-template-areas:
    'title'
    'img'
    'description'
    'hiring-button'
    'looking-jobs-button';
  grid-template-columns: 1fr;

  @media (min-width: 640px) {
    grid-template-areas:
      'title img'
      'description img'
      'hiring-button looking-jobs-button';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    padding: 24px 0 24px 24px;
  }

  @media (min-width: 992px) {
    grid-template-areas:
      '. . img'
      'title title img'
      'description description img'
      'hiring-button looking-jobs-button img'
      '. . img';
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: minmax(auto, 320px) auto auto;
    gap: 32px;
    padding: 60px 0 60px 60px;
  }
`;

export const HiringButton = styled(Button).attrs({
  variant: 'yellow',
  small: true,
})`
  grid-area: hiring-button;
  margin-right: 16px;
  margin-top: 16px;

  @media (min-width: 640px) {
    margin-right: 0;
    margin-top: 0;
  }
  @media (min-width: 992px) {
    max-width: 320px;
  }

  ::before {
    background-color: #2d2d2d;
  }
  a {
    color: #fff;
    background-color: #017eb7;
    text-align: center;
    font-family:
      Noto Sans,
      sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.25px;
    text-transform: none;
    padding: 12px 24px;
  }
  span {
    display: flex;
    align-items: center;
  }
`;
export const LookingJobsButton = styled(Button).attrs({
  variant: 'yellow',
  small: true,
})`
  grid-area: looking-jobs-button;
  margin-right: 16px;
  border: 1px solid rgb(45, 45, 45);
  border-radius: 4px;

  @media (min-width: 640px) {
    margin-left: 16px;
    margin-right: 24px;
  }

  @media (min-width: 992px) {
    margin-left: 0;
    margin-right: 0;
    max-width: 320px;
  }

  ::before {
    background-color: #2d2d2d;
  }
  a {
    color: #2d2d2d;
    background-color: #fff;
    text-align: center;
    font-family:
      Noto Sans,
      sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.25px;
    text-transform: none;
    padding: 12px 24px;
  }
  span {
    display: flex;
    align-items: center;
  }
`;

export const Title = styled(Typography.Title).attrs({ tag: 'h1' })`
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
  align-self: stretch;
  padding-right: 16px;
  max-width: 900px;
  grid-area: title;

  @media (min-width: 640px) {
    padding-right: 0;
  }

  @media (min-width: 992px) {
    font-size: 50px;
    font-weight: 700;
    line-height: normal;
  }
`;

export const Description = styled(Typography.Paragraph)`
  font-family:
    Noto Sans,
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #666;
  align-self: stretch;
  padding-right: 16px;
  grid-area: description;
  max-width: 900px;

  @media (min-width: 640px) {
    padding-right: 0;
  }

  @media (min-width: 992px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 8px;
  text-align: center;
`;
