import React from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  Description,
  HeroGraphicContainer,
  HiringButton,
  Icon,
  LookingJobsButton,
  Photo,
  Title,
} from './Hero.sc';

export const Hero = (props: {
  title: string;
  description: string;
  image: string;
}) => {
  const { title, description, image } = props;

  const { pathname } = useRouter();

  return (
    <section>
      <Container>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <LookingJobsButton
          tag="a"
          // @ts-ignore
          href={`${pathname}#for-job-seekers`}
        >
          <FormattedMessage
            id="homepage.looking-jobs-button"
            defaultMessage="I’m looking for jobs"
          />
          <Icon src="/images/homepage/icons/arrowBlack.svg" alt="Arrow" />
        </LookingJobsButton>
        <HiringButton
          tag="a"
          // @ts-ignore
          href={`${pathname}#for-employers`}
        >
          <FormattedMessage
            id="homepage.hiring-button"
            defaultMessage="I’m hiring"
          />
          <Icon src="/images/homepage/icons/arrowWhite.svg" alt="Arrow" />
        </HiringButton>

        <HeroGraphicContainer>
          <Photo src={image} />
        </HeroGraphicContainer>
      </Container>
    </section>
  );
};
